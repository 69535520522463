import ArrowIcon from "../icons/arrow.icon";
import RenLink from "../shared/RenLink";
import Image from "../media/image";
import { SinglePageProps } from "./card-list-link";

type CardLinkProps = {
  entry: SinglePageProps;
  type?: string;
};

export const CardLink = ({
  entry,
  entry: { title, illustration },
  type = "white",
}: CardLinkProps) => {
  const styles = {
    white: "u-bg--white",
    yellow: "u-bg--light-yellow",
  };

  const bgStyle = styles[type] || "u-bg--white";

  let titles = title.split(",");
  const illustrationHasAspectAboveLimit =
    illustration?.asset?.metadata?.dimensions?.aspectRatio > 1.6;

  return (
    <RenLink
      href={entry.overrideLink || entry}
      classes="o-card-listing__item c-card-link--listing"
    >
      <div className={`c-card-minor c-card-link ${bgStyle} hover-focus`}>
        <div
          className={
            illustration
              ? "c-card-link__parent"
              : "c-card-link__parent--no-illustration"
          }
        >
          <div
            className={
              illustration
                ? illustrationHasAspectAboveLimit
                  ? "c-card-link__content--under-illu"
                  : "c-card-link__content--side-illu"
                : "c-card-link__content"
            }
          >
            <div
              className={
                illustration ? "c-card-link__text" : "c-card-link__text--list"
              }
            >
              {titles.map((t, index) => (
                <h3 key={index}>{t}</h3>
              ))}
            </div>
            {illustration && (
              <Image
                image={illustration.asset}
                className="c-card-link__image"
                alt={illustration.alt || ""}
              />
            )}
            {entry.keywords && !illustration && (
              <ul className="c-card-minor__list c-card-link__list u-text--black">
                {entry.keywords.map(keyword => (
                  <li key={keyword}>{keyword}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="c-card-link__arrow">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </RenLink>
  );
};
