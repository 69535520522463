import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CardListMinor } from "../card/card.list.minor";
import { RelevantCourses } from "../course/relevant.courses";
import CallToAction from "./call.to.action";
import { CardListLink } from "../card/card-list-link";
import { CardListMajor } from "../card/card.list.major";
import { CardListFull } from "../card/card.list.full";
import ArticleMosaic from "./article.mosaic";
import RenbladCardListLink from "../renblad/renblad.card.list.link";

const components = {
  toolListing: CardListMinor,
  renbladCollectionListing: CardListMinor,
  renbladListing: RenbladCardListLink,
  conferenceAndCourseListing: RelevantCourses,
  serviceListing: CardListLink,
  categoryListing: CardListLink,
  articleListing: CardListMajor,
  preparednessListing: CardListFull,
  userGuideListing: CardListMinor,
  articleListingMosaic: ArticleMosaic,
};

const MissingComponent = ({ entries, index }) => (
  <div> Noe gikk galt {JSON.stringify(entries)} </div>
);

const DocumentListing = ({ documentListing, index, showButtons = false }) => {
  const {
    _type,
    documentListingLede,
    documentListingTitle,
    list,
    callToAction,
  } = documentListing;
  const RenderComponent = components[_type] || MissingComponent;

  let id;
  if (documentListing._type === "toolListing") {
    id = "verktoy";
  } else if (documentListing._type === "userGuideListing") {
    id = "brukerguider";
  } else if (documentListing._type === "renbladCollectionListing") {
    id = "renbladserier";
  }

  return (
    <section
      id={id}
      className={
        "c-document-listing--padding-large" +
        (_type == "articleListing"
          ? " u-bg--darkGreen "
          : index % 2 == 0
            ? " u-bg--lightgrey"
            : " u-bg--white")
      }
    >
      <div className="o-wrapper">
        {showButtons && (
          <>
            <p className="c-document-listing__top-text">
              Retningslinjer og verktøy
            </p>
            <div className={`c-document-listing__buttons`}>
              <a
                href="#renbladserier"
                className={`${id === "renbladserier" ? "active" : ""}`}
              >
                RENblad
              </a>
              <a
                href="#verktoy"
                className={`${id === "verktoy" ? "active" : ""}`}
              >
                Verktøy
              </a>
              <a
                href="#brukerguider"
                className={`${id === "brukerguider" ? "active" : ""}`}
              >
                Brukerguider
              </a>
            </div>
          </>
        )}

        <div
          className={`c-document-listing__title ${documentListingLede ? "c-document-listing__with-lede" : ""} u-padding-bottom`}
        >
          {documentListingTitle && (
            <h2
              id={documentListingTitle}
              className={
                documentListing._type == "articleListing"
                  ? "u-text--lightGreen"
                  : ""
              }
            >
              {documentListingTitle}
            </h2>
          )}

          {documentListingLede && (
            <p className="u-fontLarge">{documentListingLede}</p>
          )}
        </div>
        {RenderComponent && list && (
          <RenderComponent entries={list} index={index} />
        )}
        {callToAction && callToAction.title && (
          <CallToAction action={callToAction} />
        )}
      </div>
    </section>
  );
};

DocumentListing.propTypes = {
  documentListing: PropTypes.object,
  index: PropTypes.number,
  conferences: PropTypes.object,
};

MissingComponent.propTypes = {
  entries: PropTypes.array,
  index: PropTypes.number,
};

export default DocumentListing;
