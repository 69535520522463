import { CardMajor } from "./card.major";
import { SinglePageProps } from "./card-list-link";

type CardListMajorProps = {
  entries: SinglePageProps[];
  index: number;
};

export const CardListMajor = ({ entries, index }: CardListMajorProps) => (
  <div className="o-card-listing">
    {entries.map(entry => (
      <CardMajor entry={entry} key={entry.title} index={index} />
    ))}
  </div>
);
