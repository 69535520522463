import { client } from "helpers/sanity";
import ArticleHeader from "components/layout/article.header";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import Menu from "components/layout/menu";
import PageMeta from "components/layout/pageMeta";
import ImagePortableText from "components/portableText/image.portableText";
import PortableText from "components/portableText/portableText";
import ArticleMosaic from "components/shared/article.mosaic";
import DocumentListing from "components/shared/document.listing";
import SimpleLinkList from "components/shared/simple.link.list.js";
import {
  globalQueries,
  globalQueriesWithAds,
  portableTextQuery,
} from "helpers/sanityQueries";
import { splitArticles } from "helpers/splitArticles.js";
import { Advertisement } from "components/portableText/advertisement";
import { GetStaticProps } from "next";
import { PreviewRequirements } from "./preview";

const Single = props => {
  const {
    doc,
    settings,
    doc: {
      title = "Ingen tittel",
      lede,
      formSwitch,
      documentListingArray,
      featuredCourses,
      tags = [],
    },
    menu,
    conferences,
    articles,
    contact,
    ads,
  } = props;

  /*
    TODO: Det er vel bare aktueltsiden som bruker følgende?
     Hvorfor har dette virket uten null-sjekk før?
     Det oppfører seg rart: last inn aktuelt og det blir riktig, naviger til personvern og tilbake, og alt vises som kort.
   */
  const heroTags = tags;
  const [articlesHeadline, articlesReduced] =
    articles && heroTags ? splitArticles(articles, heroTags) : [articles, []];

  /*  Show standalone pages, these should only be accessed through direct links
      TODO: Split Article into reusable component (here, Aktuelt, Temasider, ++)
      TODO: Indexes / "Single" in current setup should be handled differently (as some already do) */
  if (doc && doc._type == "standalone") {
    const { image, portableText } = doc;
    return (
      <>
        <PageMeta doc={doc} settings={settings} />
        {menu && <Menu menu={menu} title={title} />}
        <article id="hovedinnhold">
          <ArticleHeader title={title} lede={lede} />
          {image && image.asset && (
            <section className="o-wrapper">
              <ImagePortableText
                image={image.asset}
                caption={image.caption}
                alt={image.alt}
              />
            </section>
          )}
          <section className="o-wrapper">
            <PortableText width="narrow" blocks={portableText} />
          </section>
        </article>
        <Footer contact={contact} settings={settings} />
      </>
    );
  }

  return (
    <>
      <PageMeta doc={doc} settings={settings} />
      {menu && <Menu menu={menu} title={title} />}

      {lede && (
        <Header
          type="event"
          lede={lede}
          featuredCourses={featuredCourses}
          displayContactForm={formSwitch}
        />
      )}
      <article id={lede ? undefined : "hovedinnhold"}>
        {documentListingArray && (
          <section>
            {documentListingArray.map((documentListing, index) => {
              return (
                <DocumentListing
                  documentListing={documentListing}
                  index={index}
                  key={documentListing._key}
                  conferences={conferences}
                  showButtons={doc.slug.current == "retningslinjer-og-verktoy"}
                />
              );
            })}
          </section>
        )}
        {articles && title == "Aktuelt" && (
          <div className="u-bg--lightgrey">
            <section className="o-wrapper u-padding-top-bottom">
              <ArticleMosaic entries={articlesHeadline} />
            </section>
            <Advertisement ads={ads} />
            <section className="o-wrapper u-padding-top-bottom">
              <SimpleLinkList entries={articlesReduced} />
            </section>
            <Advertisement ads={ads} />
            <br />
          </div>
        )}
      </article>

      <Footer contact={contact} settings={settings} />
    </>
  );
};

export const getStaticProps: GetStaticProps<PreviewRequirements> = async ({
  params,
}) => {
  if (!params) {
    throw new Error("Missing params, should never happen.");
  }

  const { slug = "" } = params;

  const articleQuery = {
    doc: `*[(_type == "single" || _type == "standalone") && slug.current == $slug && !(_id in path('drafts.*'))][0]
    {
      title,
      slug,
      tags[]->{...},
    }`,
    ...globalQueriesWithAds,
    articles: `*[_type == "article"
    && !(defined(allowedRoles)
    && !(length(allowedRoles.roleArray)>0
    && "" in allowedRoles.roleArray))
    && !(_id in path('drafts.*'))
    && !("Høring" in tags[]->{title}.title)]{
      _id, _type, slug, image, title, lede, byline,
      tags[]->{title}
    } | order(byline.publishDate desc)`,
  };

  let groqQuery = {
    doc: `*[(_type == "single" || _type == "standalone") && slug.current == $slug && !(_id in path('drafts.*'))][0]
    {
      ...,
      title,
      slug,
      ${portableTextQuery},
      tags[]->{...},
      "featuredCourses": featuredCourses[]->,
      image{ ..., asset-> },
      documentListingArray[]
      {
        ...,
        list[]->{..., illustration{..., asset->}, overrideLink->},
        callToAction{..., link{..., internalPage->}}
      }
    }`,
    ...globalQueries,
  };

  const groqParams = { slug };

  if (slug == "aktuelt") groqQuery = articleQuery;

  const result = await client.fetch(groqQuery, groqParams);
  if (!result || !result.doc || Object.keys(result.doc).length === 0) {
    return { notFound: true, props: { groqQuery, groqParams } };
  }

  return {
    props: { ...result, groqQuery, groqParams },
    revalidate: 3600, // 1 hour
  };
};

export async function getStaticPaths() {
  const docs = await client.fetch(
    `*[(_type == "single" || _type == "standalone") && slug.current != "aktuelt" && !(_id in path('drafts.**'))][0...10000]{slug}`
  );

  const paths = docs.map(doc => ({ params: { slug: doc.slug.current } }));

  return { paths, fallback: "blocking" };
}

export default Single;
