import CardMinor from "./card.minor";
import { SinglePageProps } from "./card-list-link";

type CardListMinorProps = {
  entries: SinglePageProps[];
  index: number;
};

export const CardListMinor = ({ entries = [], index }: CardListMinorProps) => (
  <div className="o-card-listing">
    {entries.map(entry => (
      <CardMinor entry={entry} key={entry.title} index={index} />
    ))}
  </div>
);
