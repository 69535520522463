import { CardFull } from "./card.full";
import { SinglePageProps } from "./card-list-link";

type CardListFullProps = {
  entries: SinglePageProps[];
  index: number;
};

export const CardListFull = ({ entries, index }: CardListFullProps) => (
  <div className="c-card-full--listing">
    {entries.map(entry => (
      <CardFull entry={entry} key={entry.title} index={index} />
    ))}
  </div>
);
