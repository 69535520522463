import RelevantCourseLink from "components/course/relevant.course.link";
import PortableText from "components/portableText/portableText";
import { BUMP_TO_HISTORY_DELAY } from "pages/arrangement";

interface Props {
  entries: any;
  index?: number;
  portableText?: any;
}

export const RelevantCourses = ({
  entries,
  index,
  portableText,
}: Props): JSX.Element => {
  const byDate = entries.slice(0);
  byDate.sort(function (a, b) {
    return new Date(a.courseDate).getTime() - new Date(b.courseDate).getTime();
  });

  const dateNow = new Date();
  const timeForHistory = dateNow.getTime() + BUMP_TO_HISTORY_DELAY;

  const konferanse = byDate.filter(
    entry =>
      entry.eventType == "konferanse" &&
      new Date(entry.endDate).getTime() > timeForHistory
  );
  const temadager = byDate.filter(
    entry =>
      entry.eventType == "kurs" &&
      new Date(entry.endDate).getTime() > timeForHistory
  );
  const webinar = byDate.filter(
    entry =>
      entry.eventType == "webinar" &&
      new Date(entry.endDate).getTime() > timeForHistory
  );
  const hasUpcomingEvents =
    konferanse.length > 0 || temadager.length > 0 || webinar.length > 0;

  const pastEvents = byDate
    .filter(entry => new Date(entry.endDate).getTime() < timeForHistory)
    .sort(function (a, b) {
      return (
        new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime()
      );
    });

  return (
    <section className={index && index % 2 == 1 ? "" : "u-bg--lightgrey"}>
      {hasUpcomingEvents && (
        <h2 className="u-padding-top-bottom--large">
          Kommende kurs og arrangement{" "}
        </h2>
      )}

      {konferanse.length > 0 && (
        <div className="u-padding-bottom--large">
          <h3
            className="u-padding-top-bottom--small event-type__title"
            id="konferanser"
          >
            Konferanser
          </h3>
          <ArrangementDatoType type={"Konferanser"} />
          {konferanse.map(entry => (
            <div key={entry._id}>
              <RelevantCourseLink entry={entry} />
            </div>
          ))}
        </div>
      )}

      {temadager.length > 0 && (
        <div className="u-padding-bottom--large">
          <h3
            className="u-padding-top-bottom--small event-type__title"
            id="kurs"
          >
            Kurs
          </h3>
          <ArrangementDatoType type={"Kurs"} />
          {temadager.map((entry, index) => (
            <div key={entry._id}>
              <RelevantCourseLink entry={entry} />
            </div>
          ))}
        </div>
      )}

      {webinar.length > 0 && (
        <div className="u-padding-bottom--large">
          <h3
            className="u-padding-top-bottom--small event-type__title"
            id="webinar"
          >
            Webinar
          </h3>
          <ArrangementDatoType type={"Webinar"} />
          {webinar.map((entry, index) => (
            <div key={entry._id}>
              <RelevantCourseLink entry={entry} />
            </div>
          ))}
        </div>
      )}

      {portableText && (
        <div className="o-wrapper">
          <PortableText blocks={portableText} />
        </div>
      )}

      <div className="o-wrapper">
        {pastEvents.length > 0 && (
          <div className="u-padding-bottom--large">
            <h2
              className="u-padding-top-bottom--large"
              id="Tidligere-kurs-og-arrangement"
            >
              Tidligere kurs og arrangement
            </h2>
            <ArrangementDatoType />
            {pastEvents.map(entry => (
              <RelevantCourseLink entry={entry} key={entry._id} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export const ArrangementDatoType = ({ type = "Arrangement" }) => (
  <div className="c-relevant-courses__categories">
    <p className="c-relevant-courses__title o-label-uppercase">{type}</p>
    <p className="c-relevant-courses__date o-label-uppercase">DATO</p>
    <p className="c-relevant-courses__location o-label-uppercase">STED</p>
    <p className="c-relevant-courses__status o-label-uppercase">STATUS</p>
  </div>
);
